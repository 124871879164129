<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">{{ $t('MENU.BANK_KASSA.CARDS.CARD') }}</h3>
          </div>

          <div>
            <!---  <v-dialog v-model="card_add" persistent max-width="50%">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  v-model="addCard"
                  color="success"
                  class="mr-2"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  Kartani Tuldirish
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Kartani Tuldirish</v-card-title>

                <v-card-text rd-text>
                  <v-combobox
                    v-model="select"
                    :items="getAllCorCards"
                    :error-messages="selectErrorsDEPO"
                    @input="$v.select.$touch()"
                    @blur="$v.select.$touch()"
                    required
                    label="Karta raqami"
                    outlined
                    item-text="number_16_digit"
                  ></v-combobox>
                  <v-col cols="12" :class="'p-0'" sm="6" md="12">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Sana"
                          prepend-inner-icon="event"
                          outlined
                          readonly
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(date)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>

                  <v-text-field
                    label="Summa"
                    outlined
                    dense
                    required
                    v-money="moneyDEPO"
                    v-model.lazy="amountDEPO"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="card_add = false">Yopish</v-btn>
                  <v-btn color="primary" @click="submit('DEPO')"
                    >Yuborish</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="card_remove" persistent max-width="50%">
              <template v-slot:activator="{ on, attrs }">
                <v-btn small color="red" dark v-bind="attrs" v-on="on">
                  Kartadan Yechish
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Kartadan Yechish</v-card-title>
                <v-card-text rd-text>
                  <v-combobox
                    v-model="select2"
                    :items="getAllCorCards"
                    label="Karta raqami"
                    outlined
                    required
                    :error-messages="selectErrorsWITH"
                    @input="$v.select2.$touch()"
                    @blur="$v.select2.$touch()"
                    item-text="number_16_digit"
                  ></v-combobox>

                  <v-col cols="12" :class="'p-0'" sm="6" md="12">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Sana"
                          prepend-inner-icon="event"
                          outlined
                          readonly
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(date)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>

                  <v-text-field
                    label="Summa"
                    outlined
                    dense
                    required
                    v-money="moneyWITH"
                    v-model.lazy="amountWITH"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="card_remove = false"
                    >Yopish</v-btn
                  >
                  <v-btn color="primary" @click="submit('WITH')"
                    >Yuborish</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>-->

            <v-btn to="/addcorcard" class="ml-2" color="primary"
              >{{ $t('CREATE.CARD') }} +</v-btn
            >
          </div>
        </div>

        <div class="card-body">
          <div>
            <v-card>
              <v-data-table
                :headers="headers"
                :items="getAllCorCards"
                :search="search"
                single-select
                @click:row="rowClick"
                no-data-text="Malumot kiritilmagan"
              >
                <template v-slot:[`item.expire`]="{ item }">
                  {{ item.expire | dateFilter }}
                </template>

                <template late v-slot:[`item.number_16_digit`]="{ item }">
                  <input
                    style="background: transparent; border: none"
                    v-model="item.number_16_digit"
                    type="text"
                    disabled
                  />
                </template>
                <template v-slot:[`item.remain`]="{ item }">
                  <input
                    style="background: transparent; border: none"
                    v-model="item.remain"
                    type="text"
                    disabled
                  />
                </template>
                <template v-slot:[`item.index`]="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <CorCardAction :index="item"></CorCardAction>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.v-data-table >>> tbody tr :hover {
  cursor: pointer;
}
.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  /* font-size: 0.9rem !important; */
  color: #000 !important;
  font-weight: 500 !important;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  /* font-size: 0.9rem !important; */
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: initial !important;
  overflow-y: initial !important;
}
.v-data-table__wrapper {
  overflow-x: initial !important;
  overflow-y: initial !important;
}

.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
</style>

<script>
import { VMoney } from 'v-money'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Swal from 'sweetalert2'

import CorCardAction from '@/view/content/dropdown/CorCardAction.vue'
export default {
  mixins: [validationMixin],
  validations: {
    select: { required },
    select2: { required },
    moneyDEPO: { required },
    moneyWITH: { required }
  },
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      amountDEPO: '',
      amountWITH: '',
      amount: '',
      moneyWITH: {
        decimal: '.',
        thousands: '.',
        prefix: '',
        precision: 0,
        suffix: '',
        masked: false /* doesn't work with directive */
      },
      moneyDEPO: {
        decimal: '.',
        thousands: '.',
        prefix: '',
        precision: 0,
        suffix: '',
        masked: false /* doesn't work with directive */
      },
      index: '',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      select: '',
      select2: '',
      index2: '',
      menu2: false,
      card_add: false,
      addCardWith: 'WITH',
      removeCardDepo: 'DEPO',
      card_remove: false,
      search: ''
    }
  },
  directives: { money: VMoney },
  components: {
    CorCardAction
  },

  // Action
  beforeCreate() {
    this.$store.dispatch('getAllCorCards')
  },

  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: 'Biriktirilgan', value: 'owner_name' },
        {
          text: this.$t('TABLE_HEADER.CARD_NUMBER'),
          value: 'number_16_digit'
        },
        { text: 'Muddati', value: 'expire' },
        { text: 'Biriktirlgan Mashina', value: 'owner_truck' },
        { text: 'Qoldiq', value: 'remain' },
        { text: this.$t('TABLE_HEADER.STATUS'), value: 'status' },
        { text: null, value: 'action', sortable: false }
      ]
    },
    getAllCorCards() {
      const data = this.$store.state.requests.AllCorCards
      data.forEach((element) => {
        element.remain = element.remain.toLocaleString('es-US')
        element.number_16_digit = element.number_16_digit
          .toString()
          .replace(/\B(?=(\d{4})+(?!\d))/g, ' ')
      })
      return data
    },
    selectErrorsDEPO() {
      const errors = []
      if (!this.$v.select.$dirty) return errors
      !this.$v.select.required &&
        errors.push('Karta raqami toldirilishi shart.')
      return errors
    },
    selectErrorsWITH() {
      const errors2 = []
      if (!this.$v.select2.$dirty) return errors2
      !this.$v.select2.required &&
        errors2.push('Karta raqami toldirilishi shart.')
      return errors2
    }
    // moneyDEPOErrors() {
    //   const errors = [];
    //   if (!this.$v.moneyDEPO.$dirty) return errors;
    //   !this.$v.moneyDEPO.required &&
    //     errors.push("Summa 0 bolishi mumkin emas.");
    //   return errors;
    // }
  },
  // SET_BREADCRUMB
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.BANK_KASSA.TITLE') },
      { title: this.$t('MENU.BANK_KASSA.CARDS.CARD') }
    ])
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$router.push('/corcarddetails/' + item.id + '/')
        row.select(false)
      }
    },
    submit(value) {
      if (value == 'DEPO') {
        if (this.amountDEPO == 0) {
          Swal.fire({
            title: '',
            text: 'Summa toldirilishi shart.',
            icon: 'error',
            confirmButtonClass: 'btn btn-secondary'
          })
        } else {
          if (this.select == '') {
            this.submitStatus = 'ERROR'
            Swal.fire({
              title: '',
              text: 'Karta raqami toldirilishi shart.',
              icon: 'error',
              confirmButtonClass: 'btn btn-secondary'
            })
          } else {
            const data = {
              operation_type: value,
              oper_date: this.date,
              amount: this.amountDEPO.split('.').join(''),
              card: this.select.id
            }
            this.oper_date = ''
            this.amountDEPO = ''
            this.moneyDEPO = ''
            this.date = new Date().toISOString().substr(0, 10)
            this.select = ''
            this.card_add = false
            this.card_remove = false
            this.$store.dispatch('createConCardOper', data)
            this.$v.$reset()
          }
        }
      } else if (value == 'WITH') {
        if (this.amountWITH == 0) {
          Swal.fire({
            title: '',
            text: 'Summa toldirilishi shart.',
            icon: 'error',
            confirmButtonClass: 'btn btn-secondary'
          })
        } else {
          if (this.select2 == '') {
            this.submitStatus = 'ERROR'
            Swal.fire({
              title: '',
              text: 'Karta raqami toldirilishi shart.',
              icon: 'error',
              confirmButtonClass: 'btn btn-secondary'
            })
          } else {
            const data = {
              operation_type: value,
              oper_date: this.date,
              amount: this.amountWITH.split('.').join(''),
              card: this.select2.id
            }
            this.oper_date = ''
            this.amountWITH = ''
            this.moneyWITH = ''
            this.select2 = ''
            this.card_add = false
            this.date = new Date().toISOString().substr(0, 10)
            this.card_remove = false
            this.$store.dispatch('createConCardOper', data)
            this.$v.$reset()
          }
        }
      }
    }
  }
}
// Style
</script>
