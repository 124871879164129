<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-ver"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover" style="width: 250px">
      <!-- <div  v-for="item in index" :key="item">{{item.id}} </div> -->

      <!-- {{index}} -->
      <b-dropdown-text tag="div" class="navi-item">
        <router-link :to="'/corcarddetails/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-file-1 text-success"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.DETAILS') }} </span>
        </router-link>
        <router-link :to="'/corcardupdate/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.UPDATE') }}</span>
        </router-link>
        <div class="navi-link delete" @click.stop="dialog = true">
          <span class="navi-icon">
            <i class="flaticon-close text-danger"></i>
          </span>
          <span class="navi-text">Kartani Yopish</span>
        </div>
        <v-dialog v-model="dialog" max-width="400">
          <v-card>
            <v-card-title class="headline">Kartani Yopish</v-card-title>

            <v-card-text class="headline__text">
              Kartani yopilishi bu karta buyicha amaliyotlarni tuxtatishga olib
              keladi. Karta operatsiyalari saqlanib qoladi. Karta arxivga
              o’tqaziladi.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn class="btn_submit" text @click="dialog = false">
                Yoq
              </v-btn>

              <v-btn class="btn_submit" text @click="corcardelete(index)">
                Ha
                {{ getCorpDetails }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- <router-link to="/" @click.native="corcardelete(index)" class="navi-link">
          <span class="navi-icon"
            ><i class="flaticon-delete-1 text-danger"></i
          ></span>
          <span class="navi-text">Kartani O’chirish</span>
        </router-link> -->
      </b-dropdown-text>
      <!--      <b-dropdown-text tag="div" class="navi-item">
        <a href="#" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-graph-1"></i>
          </span>
          <span class="navi-text">Order</span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a href="#" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-calendar-4"></i>
          </span>
          <span class="navi-text">Event</span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a href="#" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-layers-1"></i>
          </span>
          <span class="navi-text">Report</span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a href="#" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-calendar-4"></i>
          </span>
          <span class="navi-text">Post</span>
        </a>
      </b-dropdown-text>-->
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<style lang="scss" scoped>
.btn_submit {
  background-color: #007bff;
  color: rgb(255, 255, 255) !important;
}
.headline__text {
  font-size: 1rem !important;
  color: #000;
  font-weight: bold;

  text-decoration: underline;
}
.v-application ul,
.v-application ol {
  padding: 0 !important;
}
.dropdown-menu {
  z-index: 80;
}
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem;
}
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
// import axios from "axios";
export default {
  data() {
    return {
      dialog: false
    }
  },
  name: 'DriverAction',
  props: {
    index: Object
  },
  computed: {},
  methods: {
    corcardelete() {
      const id = {
        id: this.index.id
      }
      const data = {
        number_16_digit: this.index.number_16_digit.replace(/[^0-9]+/g, ''),
        expire: this.index.expire,
        remain: this.index.remain.split(',').join(''),
        status: false,
        owner: this.index.owner
      }

      this.$store.dispatch('updateCorpCardAction', { id, data })
    }
  },
  // methods: {
  //   corcardelete(value) {
  //     console.log(value)
  //     this.$store.dispatch("updateCorpCard",{ id, data });
  //     this.dialog  =false

  //   },
  // },

  components: {},
  mounted() {}
}
</script>
